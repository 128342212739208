@import "app/variables.scss";
@import "app/mixins.scss";

.AuthButtons {
  display: inline-flex;
  @media screen and (max-width: $aleph-screen-md-max-width) {
    @include rtlSupportInvertedProp(margin, left, 0, none);
  }

  .menu-item-width {
    min-width: 0 !important;
  }

  &__popover {
    &__menu {
      background-color: #3a454d !important;
    }
    .bp3-popover2-arrow-fill {
      fill: #3a454d !important;
    }

    .bp3-menu-item.mobile-show {
      @media screen and (min-width: $aleph-navbar-mobile-max-width) {
        display: none;
      }
    }
  }
}
