@import "app/variables.scss";
@import "app/mixins.scss";

.SortableTH {
  div {
    display: flex;

    .caret {
      @include rtlSupportInvertedProp(margin, left, $aleph-grid-size/3, null);
      transition: opacity 0.1s;

      &.hidden {
        opacity: 0;
      }
    }
  }

  &:hover div .caret.hidden {
    opacity: 0.4;
  }
  &:hover div .caret:not(.hidden) {
    opacity: 0.7;
  }
}
