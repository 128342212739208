@import "app/variables.scss";

.CollectionIndex {
  &__controls {
    .QueryTags {
      margin-top: $aleph-grid-size*1.5;
    }

    .bp3-input-group {
      margin-bottom: $aleph-grid-size;
    }
  }

  .index-item .bp3-icon {
    margin-top: 2px;
  }
}
