@import "app/variables.scss";
@import "app/mixins.scss";

.TimelineItemMenu {
  padding: $aleph-grid-size/2;

  &__hidden-input {
    position: absolute;
    visibility: none;
  }

  &__menu {
    .ColorPicker {
      padding: $aleph-grid-size;
      max-width: $aleph-grid-size*22;
      margin: auto;
    }
  }
}
