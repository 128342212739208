@import "~@blueprintjs/core/lib/scss/variables";

ul.DocumentUploadStatus__list {
  max-height: 20vh;
  overflow-y: auto;
  margin-bottom: 1rem;
  background: $white;
  border: 1px solid $light-gray1;

  .DocumentUploadTrace {
    padding: .5rem;
    display: flex;
    width: 100%;

    &__label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;
    }

    &__status {
      flex: 0 0 20px;
      height: 20px;
    }
  }
}
