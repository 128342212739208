@import "app/variables.scss";

.DiagramExportDialog {
  &.bp3-dialog {
    padding-bottom: 0;
  }

  &__section {
    margin-bottom: $aleph-grid-size*1.5;

    &:last-child {
      margin: 0;
    }

    p {
      margin: $aleph-grid-size 0 0;
    }
  }

  &__embed-code {
    .bp3-input {
      font-family: monospace;
    }
  }
}
