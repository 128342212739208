@import "app/variables.scss";
@import "app/mixins.scss";

.DocumentUploadView {
  &__content {
    margin-bottom: $aleph-grid-size*2;
    max-height: 60vh;
    overflow: auto;
  }
  &__folder {
    padding-top: 5px;
    &__label {
      color: $blue2 !important;
    }
    &__content {
      @include rtlSupportInvertedProp(padding, left, $aleph-grid-size, null);
      @include rtlSupportInvertedProp(border, left, 1px solid $aleph-border-color, null);
    }
  }
}
