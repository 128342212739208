@import "app/variables.scss";
@import "app/mixins.scss";

.EntitySetSelector {
  &__portal-container .bp3-overlay.bp3-overlay-container {
    @include rtl(right, 0, auto);
    @include rtl(left, auto, 0);
    overflow: visible;
  }

  .bp3-drawer-header {
    @include rtlSupportInvertedProp(padding, left, $aleph-grid-size*1.5, null);
    @include rtlSupportInvertedProp(padding, right, $aleph-grid-size, null);
    z-index: 1;
    .bp3-heading {
      font-size: 16px;
    }
  }

  .bp3-drawer-body {
    width: 100%;
    padding: $aleph-grid-size;

    &.blocking {
      pointer-events: none;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $light-gray5;
    opacity: .7;
    z-index: 5;
  }

  &__top-section {
    padding: $aleph-grid-size 0;
  }

  &__section {
    padding: $aleph-grid-size 0;

    &__title.bp3-heading {
      margin: 0;
      font-size: 14px;
    }
  }
}
