@import "app/mixins.scss";

/* Taming Blueprint icons and tags/badges when displayed inside tabs */
.bp3-tabs .bp3-tab .bp3-tag {
  padding: 0px 10px;
  @include rtlSupportInvertedProp(margin, left, 5px, 0);
  line-height: 1em;
  // font-size: 0.6em;
}

.bp3-tabs .bp3-large .bp3-tab .bp3-icon-standard {
  min-width: 20px;
  @include rtlSupportInvertedProp(margin, right, 10px, 0);
}

.bp3-tabs .bp3-large .bp3-tab .bp3-tag {
  padding: 0 10px;
  @include rtlSupportInvertedProp(margin, left, 10px, 0);
}

.bp3-tabs {
  &.bp3-vertical {
    > .bp3-tab-panel{
      @include rtlSupportInvertedProp(padding, left, 20px, null);
    }
  }
}

/* next 4 rules affect the add new dataset popover dialog */
.bp3-tag-input {
  @include rtlSupportInvertedProp(padding, left, 5px, 0);
}
.bp3-tag-input .bp3-tag-input-values::before, .bp3-tag-input .bp3-tag-input-values > * {
  @include rtlSupportInvertedProp(margin, right, 5px, 0);
}
.bp3-tag-input .bp3-tag-input-values {
  @include rtlSupportInvertedProp(margin, right, 7px, 0);
}
.bp3-tag > * {
  @include rtlSupportInvertedProp(margin, right, 4px, initial);
}
.bp3-tag:empty::before, .bp3-tag > :last-child {
  @include rtlSupportInvertedProp(margin, right, 0, initial);
}

/* next two rules affect the green save button in diagram page */
.bp3-tag.bp3-large::before, .bp3-tag.bp3-large > *, .bp3-large .bp3-tag::before, .bp3-large .bp3-tag > * {
  @include rtlSupportInvertedProp(margin, right, 7px, 0);
}
.bp3-tag.bp3-large:empty::before, .bp3-tag.bp3-large > :last-child, .bp3-large .bp3-tag:empty::before, .bp3-large .bp3-tag > :last-child {
  margin: 0;
}

.bp3-tag > .bp3-icon {
  @include rtlSupportInvertedProp(margin, right, 4px, 0);
}

.bp3-tab-list {
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
}

.bp3-button, .bp3-menu-item {
  @include rtl(text-align, left, right);

  .bp3-icon, .bp3-button-text {
    @include rtlSupportInvertedProp(margin, right, 7px, 0);
  }
}

.bp3-button:empty::before, .bp3-button > :last-child {
  @include rtlSupportInvertedProp(margin, right, 0, null);
}

.bp3-menu {
  @include rtl(text-align, left, right);
}

table {
  tr td, tr th {
    @include rtl(text-align, left, right);
    &.numeric, &.number {
      @include rtl(text-align, right, left);
    }
  }
}

.bp3-input-group {
  .bp3-input-action{
    &:last-child{
      @include rtl(right, 0, initial);
      @include rtl(left, initial, 0);
    }
  }

  .bp3-icon {
    &:first-child {
      @include rtl(left, 0, initial);
      @include rtl(right, initial, 0);
    }
  }

  &.bp3-large {
    .bp3-input:not(:first-child){
      @include rtlSupportInvertedProp(padding, left, 40px, null);
    }
  }

  .bp3-input-left-container {
    @include rtl(right, initial, 0);
    @include rtl(left, 0, initial);
  }

// the blueprint logic here for the input and button is:
// an input which is not the first child has an icon on it's left
// if it is not the last child there is an icon on the right
// a button which is the last child is absolute positioned at the end of the bp3-input-group
  .bp3-input {
    &:not(:last-child) {
      @include rtlSupportInvertedProp(padding, right, 30px, 10px);
    }
    &:not(:first-child) {
      @include rtlSupportInvertedProp(padding, left, 30px, 10px);
    }

    // to handle Safari input placeholder line-height weirdness
    line-height: normal !important;
  }
  > .bp3-button {
      &:last-child {
        @include rtl(right, 0, initial);
        @include rtl(left, initial, 0);
      }
  }
}

.bp3-control-group {
  > :last-child {
    @include rtl(border-radius, 0 3px 3px 0, 3px 0 0 3px);
  }

  > :first-child {
    @include rtl(border-radius, 3px 0 0 3px, 0 3px 3px 0);
  }
}

// buttons and their borders horizontal and vertical
.bp3-button-group {
  &:not(.bp3-minimal) {
    &:not(.bp3-vertical) {
      > .bp3-button{
        &:not(:last-child) {
          @include buttonNotLastChild;
        }

        &:not(:first-child) {
          @include buttonNotFirstChild;
        }
      }

      > .bp3-popover2-target:not(:first-child) .bp3-button {
        @include buttonNotFirstChild;
      }

      > .bp3-popover2-target:not(:last-child) .bp3-button{
        @include buttonNotLastChild;
      }
    }

    &.bp3-vertical {
      > .bp3-popover2-target:last-child .bp3-button,  > .bp3-button:last-child {
        border-radius: 0 0 3px 3px;
      }

      > .bp3-popover2-target:first-child .bp3-button,  > .bp3-button:first-child {
        border-radius: 3px 3px 0 0;
      }
    }
  }
}

// for the two checkboxes in settings page
.bp3-control {
  &.bp3-checkbox {
    @include rtlSupportInvertedProp(padding, left, 26px, 0);

    .bp3-control-indicator {
      @include rtlSupportInvertedProp(margin, right, 10px, -26px);
    }
  }

  input {
    @include rtl(right, null, 0);
    @include rtl(left, 0, null);
  }
}

.bp3-navbar-group {
  &.bp3-align-right {
    @include rtl(float, right, left);
  }

  &.bp3-align-left {
    @include rtl(float, left, right);
  }
}

.bp3-breadcrumb .bp3-icon {
  @include rtlSupportInvertedProp(margin, right, $aleph-icon-padding, 0);
}

.bp3-breadcrumbs {
  > li {
    &:after {
      @include rtl(transform, rotate(0deg), rotate(180deg));
    }
  }
}

.bp3-toast {
  > .bp3-icon {
    margin: 12px;
    @include rtlSupportInvertedProp(margin, right, 0, null);
  }

  .bp3-button-group{
    padding: 5px;
    @include rtlSupportInvertedProp(padding, left, 0, null);
  }
}

.bp3-tab-list {
  > :not(:last-child) {
    @include rtlSupportInvertedProp(margin, right, 20px, 0);
  }

  .bp3-tab-indicator-wrapper {
    @include rtlSupportInvertedProp(margin, left, 0, 0);
  }
}

.bp3-dialog{
  .bp3-dialog-header {
    @include rtlSupportInvertedProp(padding, left, 20px, 5px);
    .bp3-icon-large, .bp3-icon {
      @include rtlSupportInvertedProp(margin, right, 10px, 0);
    }

    .bp3-dialog-close-button {
      .bp3-icon {
        margin: 0 -7px;
      }
    }
  }

  .bp3-dialog-footer-actions .bp3-button {
    @include rtlSupportInvertedProp(margin, left, 10px, 0px);
  }
}

.bp3-menu-item {
  > * {
    @include rtlSupportInvertedProp(margin, right, 7px, 0);
  }

  > :last-child {
    margin: 0;
  }
}

// languages and countries bp3-tags in input fields, ex: in settings of a specific dataset
.bp3-tag-remove {
  @include rtlSupportInvertedProp(margin, right, -6px!important, 0!important);
  @include rtlSupportInvertedProp(padding, left, 0, 2px);
}

// document pagingation arrows and a right caret when mapping entities to excel files
.bp3-icon{
  &.bp3-icon-arrow-right, &.bp3-icon-arrow-left, &.bp3-icon-caret-right {
    @include rtl(transform, rotate(0deg), rotate(180deg));
  }
}

// this is for the delete document dialog
.bp3-alert-body .bp3-icon {
  @include rtlSupportInvertedProp(margin, right, 20px, 0px);
}

// two rules for a specific dataset settings dialog
.bp3-select {
  select {
    @include rtl(text-align, left, right);
    @include rtlSupportInvertedProp(padding, right, 25px, 10px);
  }
  &::after {
    @include rtl(right, 7px, initial);
    @include rtl(left, initial, 7px);
  }
}

// =================> Begin Overrides from react-ftm <===================

.TableView__close {
  @include rtl(right, 10px, initial);
  @include rtl(left, initial, 10px);
}

.VertexMenu {
  @include rtl(left, 70px, initial !important);
  @include rtl(right, initial !important, 70px);
}

.TableEditor .data-grid-container .data-grid .cell {
  @include rtl(text-align, left, right);
}

.EntityViewer__property-list-item__value {
  .bp3-input-group {
    .bp3-input {
      &.TextEdit__singleInput {
        @include rtlSupportInvertedProp(padding, left, 0!important, 30px!important);
      }
    }
  }
}
// When mapping excel coloumns to properties
[dir="rtl"] .bp3-table-header-content {
  direction: rtl;
}

// =================> End Overrides from react-ftm - diagrams <===================
