@import "app/variables.scss";

.HtmlViewer {
  overflow-x: hidden;
  padding: $aleph-grid-size;
  box-shadow: $pt-elevation-shadow-1;
  margin-bottom: 2 * $aleph-grid-size;
  line-height: 1.4;
  font-size: 1.1 * $aleph-font-size;
}
