@import "app/variables.scss";

.CollectionStatistics {
  .Statistics {
    padding: 0;
  }

  &__heading {
    padding-bottom: $aleph-grid-size*2;
    margin-bottom: $aleph-grid-size;
    border-bottom: 1px solid $aleph-border-color;

    &__total, &__label, &__icon {
      color: $blue1;
      text-align: center;
      margin-top: 0;
      margin-bottom: 2px;
    }

    &__total {
      font-size: 40px;
    }

    &__label {
      font-size: 16px;
    }

  }
}
