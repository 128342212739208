@import "app/mixins.scss";
@import "app/variables.scss";

$navbar-horizontal-padding: $aleph-grid-size*.8;

.Navbar {
  .bp3-navbar {
    display: flex;
    padding: 0 $aleph-content-padding;
    justify-content: space-between;
    background-color: $dark-gray1 !important;

    @media screen and (max-width: $aleph-navbar-mobile-max-width) {
      padding: 0 $aleph-mobile-content-padding;
    }

    .back-button {
      display: none;
    }

    a {
      color: inherit;

      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }

    form {
      .bp3-control-group {
        width: 100%;
        align-items: center;
      }

      input {
        background: #fff;
        color: $pt-text-color;
      }
    }

    .icon {
      display: none;
      @media screen and (max-width: $aleph-navbar-mobile-max-width) {
        @include rtl(float, right, left);
        margin-top: 1em;
      }
    }
  }

  .bp3-navbar-heading {
    vertical-align: middle;
    font-weight: bold;
    margin: 0 $aleph-grid-size;
  }

  &__left-group {
    @include rtlSupportInvertedProp(padding, right, $navbar-horizontal-padding, null);

    @media screen and (max-width: $aleph-navbar-mobile-max-width) {
      &.hide {
        display: none;
      }
    }
  }

  &__middle-group {
    flex: 4 1 auto;
    @media screen and (max-width: $aleph-navbar-mobile-max-width) {
      display: none !important;
    }
    &.mobile-force-open {
      display: block !important;
    }
  }

  &__home-link {
    height: 28px;
    display: table;

    &__text {
      font-size: 16px;
      font-weight: 600;
      vertical-align: middle;
      display: table-cell;
      white-space: nowrap;
      @include rtlSupportInvertedProp(padding, left, $navbar-horizontal-padding, null);
    }

    img {
      height: 28px;
      display: table-cell;
    }
  }


  &__search-container {
    flex: 4 1 auto;
    padding: $aleph-grid-size $aleph-grid-size/2;
    max-width: $aleph-grid-size*50;
    @include rtlSupportInvertedProp(margin, right, $navbar-horizontal-padding*2, null);

    &__content {
      display: flex;
    }

    &__searchbar {
      flex-grow: 4;

      input.bp3-input {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-radius: 0!important;
        box-shadow: none!important;
        padding: 0 30px!important;
      }
    }

    &__search-tips {
      @media screen and (max-width: $aleph-screen-md-max-width) {
        display: none !important;
      }
    }

    .bp3-button {
      margin: 0;
    }
  }

  &__collections-button.mobile-hide {
    @media screen and (max-width: $aleph-navbar-mobile-max-width) {
      display: none !important;
    }
  }

  &__mobile-back-button, &__mobile-search-toggle, &__mobile-search-divider {
    @media screen and (min-width: $aleph-navbar-mobile-max-width) {
      display: none;
    }
  }

  .bp3-button, .bp3-menu {
    border-radius: 0;
  }

  .bp3-divider {
    margin: 0 5px !important;
  }

  .mobile-hidden {
    @media screen and (max-width: $aleph-navbar-mobile-max-width) {
      display: none;
    }
  }
}
