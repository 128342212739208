@import "app/variables.scss";
@import "app/mixins.scss";

$transition-duration: .3s;
$sidebar-padding: $aleph-grid-size*2;


.InvestigationSidebar {
  width: $aleph-investigation-sidebar-width;
  @include rtlSupportInvertedProp(border, right, 1px solid $aleph-border-color, none);
  @include rtlSupportInvertedProp(margin, right, $aleph-content-padding, 0);
  @include rtlSupportInvertedProp(padding, right, $aleph-content-padding, 0);

  @media screen and (max-width: $aleph-screen-sm-max-width) {
    width: 100%;
    border: none !important;
    border-bottom: 1px solid $aleph-border-color !important;
    margin: 0 0 $aleph-content-padding !important;
    padding: 0 0 $aleph-content-padding !important;
  }

  .SchemaCounts {
    margin-bottom: $aleph-grid-size;
  }

  &__content {
    @media screen and (min-width: 450px) and (max-width: $aleph-screen-sm-max-width) {
      display: flex;
      align-items: flex-start;
      margin: -$aleph-content-padding/2;
    }
  }

  &__section {
    padding: 15px;
    border-radius: 5px;
    background: $light-gray5;
    margin: $aleph-content-padding 0;
    border: none;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (min-width: 450px) and (max-width: $aleph-screen-sm-max-width) {
      width: 50%;
      margin: $aleph-content-padding/2;
    }

    &__title.bp3-heading {
      color: $aleph-investigation-theme;
      padding-bottom: 5px;
    }
  }
}
