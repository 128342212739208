@import "app/variables.scss";
@import "app/mixins.scss";

.DateFacet {
  &.bp3-card {
    padding-top: $aleph-grid-size*2;
    padding-bottom: $aleph-grid-size/2;
    position: relative;
  }

  &__label {
    padding-bottom: $aleph-grid-size;
    font-size: 1.1em;
    font-weight: bold;
  }

  .bp3-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__secondary {
    margin-bottom: $aleph-grid-size/2;
    @include rtl(text-align, right, left);

    &, .bp3-button-text {
      font-size: 12px;
    }

    .bp3-button-text:hover {
      text-decoration: underline;
    }

    .bp3-button {
      display: contents;
    }
  }

  .ErrorSection {
    min-height: unset;

    .bp3-non-ideal-state {
      .bp3-heading {
        font-size: 16px;
      }
    }
  }
}
