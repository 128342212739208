@import "app/variables.scss";

.ItemOverview {
  height: auto !important;

  &__heading {
    padding: ($aleph-grid-size * 4) 0;

    .preview & {
      background-color: $aleph-breadcrumbs-background;
      padding: $aleph-grid-size*3 $aleph-grid-size*2;
      border: none;
    }

    .profile & {
      background-color: $aleph-profile-dark;
      color: white;
      padding: $aleph-grid-size*4 $aleph-grid-size*2 $aleph-grid-size*2;
      border-radius: $aleph-grid-size $aleph-grid-size 0 0;

      .bp3-text-muted {
        color: white;
      }
    }

    &__title, &__subtitle, &__description {
      margin: 10px 0;
      overflow-wrap: break-word;
    }

    &__title {
      line-height: 1.5;
    }

    &__last-viewed {
      font-size: $pt-font-size-small;
    }
  }

  &__callout {
    padding: 0 0 2*$aleph-grid-size;

    .preview & {
      margin-top: 2*$aleph-grid-size;
      padding-bottom: $aleph-grid-size;
    }
    .profile & {
      padding-bottom: $aleph-grid-size;
    }
  }

  &__content {
    border-top: 1px solid $aleph-border-color;

    .preview &, .profile & {
      border: none;
    }

    &__section {
      padding: $aleph-grid-size 0;
      border-bottom: 1px solid $aleph-border-color;

      .preview &:first-child {
        padding-top: 0;
        margin-top: $aleph-grid-size/2;
      }

      &:last-child {
        border: none;
      }
    }
  }
}
