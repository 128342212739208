@import "app/variables.scss";
@import "app/mixins.scss";

.ListScreen {
  &__schema-counts {
    @include rtlSupportInvertedProp(padding, right, $aleph-content-padding, null);
    @include rtlSupportInvertedProp(border, right, 1px solid $aleph-border-color, null);

    @media screen and (max-width: $aleph-screen-sm-max-width) {
      padding: 0 0 $aleph-content-padding !important;
      margin-bottom: $aleph-content-padding;
      border: none !important;
      border-bottom: 1px solid $aleph-border-color !important;
    }
  }

  .SchemaCounts {
    width: $aleph-grid-size*22.5;

    @media screen and (max-width: $aleph-screen-sm-max-width) {
      width: 100%;
    }
  }

}
