@import "app/variables.scss";

.CollectionViews {
  &__tabs {
    & > .bp3-tab-list {
      padding-bottom: 10px;
      border-bottom: 1px solid $aleph-border-color;
    }

    .DualPane {
      padding: 0;
    }
  }
}
