@import "app/variables.scss";
@import "app/mixins.scss";

.DocumentDropzone {
  flex-grow: 1;
  &__content {
    min-height: 100%;
    &.active {
      position: relative;
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        @include rtl(left, 0, null);
        @include rtl(right, null, 0);
        background: rgba($aleph-breadcrumbs-background, .4);
        box-shadow: inset 0 0 3px $blue1;
        border-radius: $aleph-border-radius;
      }
    }
  }
}
