@import "app/variables.scss";
@import "app/mixins.scss";

.CollectionLabel {
    .bp3-icon, .bp3-spinner {
        @include rtlSupportInvertedProp(margin, right, $aleph-icon-padding, null);
    }

    .bp3-spinner {
      display: inline-block;
    }
}

.CollectionUpdateStatus {
  &__popover {
    .bp3-popover2-arrow-fill {
      fill: $light-gray4 !important;
    }
  }
}
