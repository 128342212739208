@import "app/variables.scss";

.EmailViewer {
  margin-bottom: $aleph-grid-size * 2;

  .email-header table {
    width: 100%;
    th, td {
      box-shadow: none !important;
      border-bottom: 1px solid $aleph-border-color;
    } 
  }

  .email-body {
    padding: $aleph-grid-size;
    border: 0;
    box-shadow: unset;

    pre {
      padding: 0;
      border: 0;
      box-shadow: unset;
    }
  
  }
}