@import "app/variables.scss";
@import "app/mixins.scss";

.Breadcrumbs {
  z-index: 1;
  padding: $aleph-grid-size/2 $aleph-content-padding;
  background-color: $aleph-breadcrumbs-background;

  @media screen and (max-width: $aleph-screen-sm-max-width) {
    @include rtlSupportInvertedProp(padding, left, $aleph-mobile-content-padding, $aleph-mobile-content-padding);
  }

  &.casefile {
    background-color: $aleph-investigation-theme;
  }

  &.dataset {
    background-color: $aleph-dataset-theme;
  }

  &.casefile, &.dataset {
    .bp3-breadcrumb {
      color: white;
    }

    .bp3-divider {
      border-color: $light-gray4;
    }
  }

  &__inner-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__main, &__right {
    margin: 2px 0;
  }

  &__main {
    @include rtlSupportInvertedProp(margin, right, $aleph-content-padding, 0);
  }

  &__right {
    display: flex;

    .bp3-button-group {
      align-items: center;
    }

    .bp3-divider:last-child {
      display: none;
    }

    .bp3-control-group, .bp3-input, .bp3-button-group {
      border-radius: $aleph-border-radius !important;
    }

    .bp3-input-group {
      min-width: $aleph-grid-size*20.5;
    }
  }

  .bp3-breadcrumbs {
    height: auto;
    margin: 1px 0;

    & > li {
      margin: 5px 0;
      &::after {
        margin: 0 10px;
      }
    }
  }

  .bp3-breadcrumb {
    font-size: $aleph-font-size;

    &.CollectionLabel {
      font-weight: 600;

      .bp3-spinner-head {
        stroke: white;
      }
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .bp3-breadcrumb-current {
    color: $blue1;

    .bp3-spinner-head {
      stroke: $blue1;
    }
  }

  .EntityLabel > * {
    display: inline-flex !important;
    white-space: nowrap !important;
  }
}
