@import "~src/app/tables";
@import "app/mixins.scss";

.StatusTable {
  @extend .data-table;

  tr td, tr th {
    vertical-align: middle;
  }

  td.text {
    @include rtlSupportInvertedProp(padding, left, 0, null)
  }
}
