@import "app/variables.scss";
@import "app/mixins.scss";

.EntitySetSelectorSection {
  &__title-container {
    margin-bottom: $aleph-grid-size/2;
  }

  &__title {
    &.bp3-button {
      justify-content: flex-start;
      background-color: $aleph-breadcrumbs-background !important;
    }
    padding: $aleph-grid-size/2;
    position: relative;

    .bp3-heading {
      margin: 0;
    }

    .Count {
      position: absolute;
      @include rtl(right, $aleph-grid-size, initial);
      @include rtl(left, initial, $aleph-grid-size);
    }
  }

  &__content {
    padding-bottom: $aleph-grid-size;
  }

  &__create {
    padding: $aleph-grid-size 3px;
  }

  .ErrorSection {
    min-height: 100px;
  }

  .bp3-non-ideal-state {
    .bp3-icon {
      svg {
        width: 30px;
        height: 30px;
      }
    }
    .bp3-non-ideal-state-visual {
      font-size: 30px;
      margin-bottom: $aleph-grid-size;
    }
    .bp3-heading {
      font-size: 16px;
    }
  }
}
