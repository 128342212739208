@import "app/variables.scss";
@import "app/mixins.scss";

.MappingKeyAssign {
  &__item {
    position: relative;
    color: white;
    box-shadow: none !important;
    margin: $aleph-grid-size 0;

    &__close {
      position: absolute;
      top: $aleph-grid-size;
      @include rtl(right, $aleph-grid-size, null);
      @include rtl(left, null, $aleph-grid-size);

      svg {
        fill: white !important;
      }
    }

    &__title.bp3-heading {
      color: white;
      @include rtlSupportInvertedProp(margin, right, $aleph-grid-size*5, null);
    }

    &__property {
      display: table;
      width: 100%;

      &__label, &__value {
        display: table-cell;
      }

      &__value {
        width: 75%;
        @include rtlSupportInvertedProp(padding, left, $aleph-grid-size*1.5, null);
      }

      &__label {
        width: 25%;
        margin: 10px 0;
      }
    }

    .bp3-form-helper-text {
      color: white !important;
    }

    &__keyHelp {
      &__main {
        margin-bottom: $aleph-grid-size/2;
      }
      &__toggle {
        font-weight: bold;
        margin-top: 0 !important;
        @include rtlSupportInvertedProp(margin, left, -$aleph-grid-size/2, null);

        .bp3-icon {
          color: white !important;
        }
      }
    }
  }

  .MappingLabel {
    color: white !important;
  }

  &__properties {
    list-style: none;
  }
}
