@import "app/variables.scss";
@import "app/mixins.scss";

.CollectionReference {
  &__section {
    .key {
      margin-bottom: 5px;
      color: $gray1 !important;
    }

    .value {
      word-wrap: break-word;
    }

    .bp3-input-group {
      margin-bottom: 5px;
      .bp3-input {
        @include rtlSupportInvertedProp(padding, right, 30px!important, 10px!important);
      }
    }
  }

  .bp3-divider {
    margin: $aleph-grid-size*1.5 0 !important;
  }
}
