@import "app/variables.scss";
@import "app/mixins.scss";

.Facet {
  margin-bottom: $aleph-grid-size;

  & > .opener {
    display: flex;
    padding: 0 $aleph-grid-size;
    align-items: center;
    cursor: pointer;
    background: $aleph-facet-background !important;

    &.active {
      font-weight: bold;
    }

    &:not(.clickable) {
      pointer-events: none;
    }

    & > .caret {
      @include rtlSupportInvertedProp(margin, right, $aleph-grid-size * 0.5, null);
      @include rtl(transform, rotate(0deg), rotate(180deg));
      transition: transform 100ms;

      &.rotate {
        transform: rotate(90deg);
      }
    }

    .FacetName {
      padding: $aleph-grid-size 0;
      font-size: 1.1em;
      font-weight: bold;

      .bp3-icon {
        @include rtlSupportInvertedProp(margin, right, 10px !important, null);
      }
    }

    .FilterCount {
      @include rtlSupportInvertedProp(padding, left, $aleph-grid-size, null);
      font-weight: normal;
    }

    & > .ClearButton {
      position: absolute;
      top: $aleph-grid-size/2;
      @include rtl(right, $aleph-grid-size/2, null);
      @include rtl(left, null, $aleph-grid-size/2);

      &a:hover {
        // Element is on a background (set by .clickable), so invert its own background.
        background: $aleph-content-background;
      }
    }

    .Facet__action, .bp3-tag {
      position: absolute;
      @include rtl(right, $aleph-grid-size, null);
      @include rtl(left, null, $aleph-grid-size);
    }

    .bp3-tag {
      padding-top: 0;
      padding-bottom: 0;
      font-weight: bold;
      background-color: $aleph-theme-intent;
      color: white;
    }
  }

  .CheckboxList {
    margin-top: $aleph-grid-size;
  }

  &__no-options {
    color: $aleph-greyed-text;
    margin: 0 $aleph-grid-size*2.5 $aleph-grid-size*1.5;
  }

  .DateFacet.bp3-card {
    box-shadow: none;
    padding: $aleph-grid-size*1.5 $aleph-grid-size 0;
  }

  p {
    text-align: center;
  }

  .spinner {
    margin: $aleph-grid-size auto $aleph-grid-size auto !important;
  }

  .ShowMore {
    margin-top: $aleph-grid-size;
    font-weight: bold;
  }

  .updating {
    opacity: 0.5;
  }
}
